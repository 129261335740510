
import ContentCard from 'portal/components/common/content-card/ContentCard.vue';
import ContentCardSwiper from 'portal/components/common/content-card/ContentCardSwiper.vue';
import ContentBlockWrapperWithHeader from 'portal/components/common/content-block/ContentBlockWrapperWithHeader.vue';

export default {
    name: 'OneToFourBlock',

    components: {
        ContentBlockWrapperWithHeader,
        ContentCardSwiper,
        ContentCard,
    },

    props: {
        bigCard: {
            type: Object,
            required: true,
            default: () => ({}),
        },

        smallCards: {
            type: Array,
            required: true,
            default: () => [],
        },


        smallMediasTitle: {
            type: String,
            default: null,
        },

        buttonText: {
            type: String,
            default: null,
        },

        buttonLink: {
            type: String,
            default: null,
        },

        substrate: {
            type: Boolean,
            default: false,
        },

        firstScreenBlock: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        showTags() {
            return this.$deviceIs.mobile;
        },

        hasBigCard() {
            return Object.keys(this.bigCard).length > 0;
        },

        linkTitle() {
            return `/media/${this.bigCard?.id}/${this.bigCard?.slug}`;
        },
    },
};
